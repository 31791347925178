import React from "react"
import { PageProps } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../../components/seo"
import { GridContainer } from "../../components/grid"
import { PhotoHero, SimpleTitle } from "../../components/heros"
import { CardsContainer, SecondaryCard } from "../../components/cards"
import { NavigationDetailCTA } from "../../components/ctas"
import { PrimaryLink } from "../../components/links"

const StyledCardsContainer = styled(CardsContainer)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 7;
  }
`

const TeamDevelopmentPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="Teamontwikkeling"
      description="Een goede samenwerking in een team zorgt voor meer werkplezier, meer effectiviteit en meer innovatie. Ga aan de slag met jouw team(s) en zorg dat de kwaliteit, productiviteit en het plezier wordt vergroot!"
    />
    <GridContainer>
      <SimpleTitle>Teamontwikkeling</SimpleTitle>
      <PhotoHero
        title="Alleen ga je snel, samen kom je verder"
        subtitle="Een goede samenwerking in een team zorgt voor meer werkplezier, meer effectiviteit en meer innovatie. Samenwerken is dus behoorlijk belangrijk, maar soms ook heel erg moeilijk, want al die verschillende types in een groep vormen nog niet zomaar een hecht en veilig team. Door middel van een intensieve begeleiding of een teamopstelling worden helpende en belemmerende patronen blootgelegd en zijn jullie tot slot als groep in staat om de regie in eigen handen te nemen en stappen te zetten in de samenwerking."
        image={
          <StaticImage
            src="../../images/team.jpg"
            alt="Teamontwikkeling"
            placeholder="blurred"
            quality={100}
          />
        }
      />
      <StyledCardsContainer title="Bekijk ons aanbod:">
        <SecondaryCard title="Team TMA">
          <p>
            Als je op een positieve en leuke manier met het team aan de slag wil
            gaan, en meer zicht wil krijgen op de talenten in het team, dan is
            de TMA teamdag echt iets voor jou. Dit is een heel effectieve manier
            om de talenten en gedragsvoorkeuren van de teamleden in kaart te
            brengen. De teamanalyse maakt inzichtelijk wat een team motiveert en
            hoe de talenten van de teamleden zich daartoe verhouden. De
            Beukelaar groep werkt met de TMA tool omdat dit gemeenschappelijke
            taal geeft voor het bespreken van gedrag in teams. Niet alleen op
            individueel niveau heeft het dus voordelen om de talenten van
            medewerkers te analyseren en zichtbaar te maken, maar op op groeps-
            en organisatieniveau. Het zal de onderlinge samenwerking versterken.
            Medewerkers leren elkaars talenten te benutten en begrijpen waarom
            ze met de ene collega makkelijker werken dan met de andere. Het
            vieren en benutten van de verschillen in een team zorgt voor betere
            samenwerking en een prettig werkklimaat.
          </p>
        </SecondaryCard>
        <SecondaryCard title="Teamopstelling">
          <p>
            Het werken met opstellingen is een manier om snel zicht te krijgen
            op wat er speelt binnen het team of organisatie. Opstellingen maken
            de verhoudingen zichtbaar. Tijdens een opstelling wordt een
            ontwikkelingsvraag of thema vanuit het team ingebracht en door het
            fysiek opstellen van representanten voor teamleden of andere thema’s
            verschijnen als vanzelf de (verborgen) dynamieken die zich afspelen
            binnen het team of organisatie. De Beukelaar Groep werkt ook
            regelmatig met paardenopstelling. Wanneer paarden meedoen in een
            opstelling is dat een enorme verrijking. Een paard is erg gevoelig
            en kan hierdoor haarfijn ons onbewuste waarnemen en erop reageren.
            Een paard is namelijk van nature een sociaal dier dat in een kudde
            leeft. Daar waar geen balans is, waar verbindingen nodig zijn,
            andere posities ingenomen moeten worden, waar beweging nodig is, zal
            het paard gaan reageren. Hierdoor wordt zichtbaar wat ten grondslag
            ligt aan een bepaald symptoom of gedrag.
          </p>
        </SecondaryCard>
        <SecondaryCard title="Teamcoaching">
          <p>
            Kijk eens om je heen. Met je collega’s breng je meer tijd door dan
            met je vrienden. Dat betekent allerminst dat je vrienden moet worden
            met je collega’s. Het betekent wel dat er genoeg ruimte moet zijn
            voor iedereen in het team om zichzelf te zijn. Vaak begint het
            gevoel om onderdeel te zijn van je team met het accepteren van de
            onderlinge verschillen. Zo zien wij dat teams die geleerd hebben de
            onderlinge verschillen te accepteren en waarderen, een stuk
            effectiever werken dan teams die dat nog niet kunnen. Teamcoaching
            wordt vaak ingezet op het moment dat er signalen zijn van een
            moeizame samenwerking, zoals een hoog verloop en verzuim, strijd en
            conflicten en het niet halen van deadlines. Zonde! De meest
            effectieve manier van teamcoaching is voor de brand ontstaat al
            beginnen te blussen. Door vroegtijdig aandacht te geven aan de
            teamsamenwerking voorkom je grote moeilijkheden. De Beukelaar Groep
            helpt je om in een vroeg stadium, of wanneer er al conflict is, de
            balans in de dynamiek te herstellen.
          </p>
        </SecondaryCard>
      </StyledCardsContainer>
      <NavigationDetailCTA>
        <PrimaryLink to="/diensten/organisatieontwikkeling/">
          NAAR ORGANISATIEONTWIKKELING
        </PrimaryLink>
        <PrimaryLink to="/diensten/leiderschap/">NAAR LEIDERSCHAP</PrimaryLink>
        <PrimaryLink to="/diensten/diversiteit-en-inclusie/">
          NAAR DIVERSITEIT &amp; INCLUSIE
        </PrimaryLink>
      </NavigationDetailCTA>
    </GridContainer>
  </>
)

export default TeamDevelopmentPage
